import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as formik from 'formik';
import styled from 'styled-components';
import queryString from 'query-string';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { H1 } from 'components/common/Header';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { Field, FieldWrapper, FieldLabel, FieldWithExtras } from 'components/form/Field';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { Content } from 'components/layout/Content';
import { loginValidationSchema } from './validation';
import { apiClient, parseAPIError } from 'lib/api';
import { redirectToMainApp, redirectToNextUrl, getLocationState} from 'lib/routing';
import { DEFAULT_LOGIN_REDIRECT_URL } from 'lib/constants';
import { applicationState } from 'data/state';

const RegisterMessageContainer = styled.div`
  color: ${(props) => props.theme.colors.darkgrey};
  font-size: 14px;
  text-align: center;
`;

const RegisterLink = styled.a`

`;

const LoginForm = styled(PrimaryForm)`

`;

const ForgotPasswordLink = styled(Link)`
  float: right;
  text-transform: none;
  font-size: 13px;
`;

const AcceptTermsLink = styled.div`
  position: relative;
  top: -57px;
  left: 24px;
  text-transform: none;
  font-size: 13px;
  height: 0;
`;


function LoginFormComponent(props) {
  // Put more stuff in this component so we get access to the Formik values prop for
  // the signup link
  const {values, errors, isSubmitting} = props;
  const [acceptTerms, setAcceptTerms] = useState(false);

  let registerUrl = DEFAULT_LOGIN_REDIRECT_URL +'/choose-account-type';
  let termsOfUseUrl = DEFAULT_LOGIN_REDIRECT_URL +'/terms-of-use';
  let privacyPolicyUrl = DEFAULT_LOGIN_REDIRECT_URL +'/privacy-policy';
  let welcomeText = 'Need an account?';
  let registerButtonText = 'Register';

  return (
      <>
      {/* <RegisterMessageContainer>
        <span>{welcomeText} </span>
        <RegisterLink
          href={registerUrl}
        >
          {registerButtonText}
        </RegisterLink>
      </RegisterMessageContainer> */}

      <LoginForm>
        <FieldWithExtras type="email" name="email" label="email" tabIndex="1" autofocus />
        <FieldWithExtras
          type="password"
          name="password"
          tabIndex="2"
          labelComponent={
            <FieldLabel>
              Password
              <ForgotPasswordLink
                to={{
                  pathname: '/password/reset',
                  state: { email: values.email },
                }}
              >
                Forgot your password?
              </ForgotPasswordLink>
            </FieldLabel>
          }
        />
        <CheckboxInput name="acceptTerms" onChange={(e) => setAcceptTerms(e.target.checked)}/>
        <AcceptTermsLink>I agree to the <a target="_blank" href={termsOfUseUrl}>Terms of Use</a> and <a target="_blank" href={privacyPolicyUrl}>Privacy Policy</a>.</AcceptTermsLink>
        <PrimaryButton type="submit" disabled={isSubmitting || (!isSubmitting && !acceptTerms)}> Sign In </PrimaryButton>
      </LoginForm>
    </>
  );
}

const LOGIN_URL = '/web/login';

function login(email, password) {
  return apiClient.post(LOGIN_URL, { email: email, password: password });
}

function Login(props) {
  const queryArgs = queryString.parse(props.location.search);
  const nextUrl = queryArgs.next && queryArgs.next.replace('-', '=');  // to allow nextUrls with query parameters
  const initialEmail = getLocationState(props.location, 'email') || '';  // prepopulate email

  const submitLogin = (values, actions) => {
    login(values.email, values.password)
      .then((resp) => {
        const data = resp.data;

        if (data.state_token) {
          // Not logged in yet, need MFA
          const stateToken = data.state_token;
          const isNewDevice = data.is_new_device;
          const isTrustTokenUser = data.otp_device_id;

          if (isNewDevice) {
            if (isTrustTokenUser === 0) { // trusttoken user
              let search = `?state_token=${stateToken}&user=0`;
              if (nextUrl) {
                const nextEncoded = encodeURIComponent(nextUrl);
                search += `&next=${nextEncoded}`;
              }

              const newLocation: any = {
                pathname: '/login/mfa-verify',
                search: search,
                state: {stateToken: stateToken},
              };
              props.history.push(newLocation);
              return;
            } else { // tusd new user
              // Need to set up MFA
              let search = `?login_state_token=${stateToken}`;
              const newLocation: any = {
                pathname: '/mfa-setup',
                search: search,
                state: {loginStateToken: stateToken},
              };
              props.history.push(newLocation);
              return;
            }
          } else { // tusd old user
            // MFA is setup, but need to do MFA verification
            let search = `?state_token=${stateToken}`;
            if (nextUrl) {
              const nextEncoded = encodeURIComponent(nextUrl);
              search += `&next=${nextEncoded}`;
            }
            const newLocation: any = {
              pathname: '/login/mfa-verify',
              search: search,
              state: {stateToken: stateToken},
            };
            props.history.push(newLocation);
            return;
          }
        } else {
          if (nextUrl) {
            redirectToNextUrl(nextUrl);
          } else {
            redirectToMainApp();
          }
        }
      })
      .catch((error) => {
        const errorInfo = parseAPIError(error);
        if (errorInfo.message.toLowerCase().includes("password"))
          actions.setErrors({password: errorInfo.message});
        else
          actions.setErrors({acceptTerms: errorInfo.message});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Content>
      <H1> Sign in</H1>
      <Formik
         initialValues={{ email: initialEmail, password: '', acceptTerms: false }}
         onSubmit={submitLogin}
         component={LoginFormComponent}
         validationSchema={loginValidationSchema}
      />
    </Content>
  );
}

export { Login };
