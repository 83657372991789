import React from 'react';
import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';
import { SuccessGraphic } from 'components/common/SuccessGraphic';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { getLocationState } from 'lib/routing';

const EmailVerificationSuccess = (props) => {
  const email = getLocationState(props.location, 'email');

  const successRedirect = () => {
    const newLocation = {
      pathname: '/login',
      state: { email: email },   // grab email somehow
    };
    props.history.push(newLocation);
  };

  return (
    <Content centered>
      <SuccessGraphic/>
      <H1> Your email address has successfully been verified </H1>
      <Explanation> Please log in to your account </Explanation>
      <PrimaryButton hollow onClick={successRedirect}> Continue </PrimaryButton>
    </Content>
  );
}

export { EmailVerificationSuccess };
