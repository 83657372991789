import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// This is like a link after a confirmation page to go home or something
const ReturnLinkWrapper = styled.div`
  text-transform: uppercase;
  margin-top: 50px;
`;

const ReturnLink = (props) => (
  <ReturnLinkWrapper>
    <Link {...props}> {props.children} </Link>
  </ReturnLinkWrapper>
);

export { ReturnLink };
