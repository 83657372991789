import { Login } from 'components/Login';
import { Logout } from 'components/Logout';
import { PasswordReset } from 'components/PasswordReset';
import { MFAVerify, MFASetup } from 'components/MFA';
import { EmailVerification } from 'components/EmailVerification';
import { Home } from 'components/Home';
import { MFAReset } from 'components/MFAReset/index';

const routes = [
  {
    component: Login,
    exact: true,
    path: '/login',
  },
  {
    component: MFAVerify,
    exact: true,
    path: '/login/mfa-verify',
  },
  {
    component: Logout,
    exact: true,
    path: '/logout',
  },
  {
    component: MFASetup,
    exact: true,
    path: '/mfa-setup',
  },
  {
    component: MFAReset,
    path: '/mfa/reset',
  },
  {
    component: PasswordReset,
    path: '/password/reset',
  },
  {
    component: EmailVerification,
    path: '/email/verify',
  },
  {
    component: Home,
    path: '/home',
  },
];

export default routes;
