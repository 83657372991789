import axios from 'axios';

import { getCookie } from 'lib/cookie';

const apiClient = axios.create({
  baseURL: '/api',
});

apiClient.interceptors.request.use((config) => {
  const csrfToken = getCookie('csrf_token');
  config.headers['CSRF-Token'] = csrfToken;
  return config;
}, (error) => {
  return Promise.reject(error);
});

function registerUnauthorizedInterceptor(history) {
  // Redirect to login page if a 401 response is ever received
  // We need to use React-router history to move pages, so need the history object

  apiClient.interceptors.response.use((response) => {
      return response;
  }, (error) => {
      if (error.response.status === 401) {
        if (!window.location.pathname.startsWith('/login')) {
          history.push('/login');
        }
      }
      return Promise.reject(error);
  });
}

function parseAPIError(error) {
  // Return a normalized error "object" with a message and code
  const result = {
    message: null,
    code: null,         // enum string
    status: null,
  };
  if (error.response) {
    const data = error.response.data;
    const status = error.response.status;

    if (status === 500) {
      result.message = 'Server error';
      // result.code = 'server_error',
      result.serverError = true;
    } else {
      result.message =  data.message;
      result.error = data.code;
    }
    result.status = status;
  }
  return result;
}

export { apiClient, registerUnauthorizedInterceptor, parseAPIError };
