const ROOT_DOMAIN = process.env.ROOT_DOMAIN || 'truecnh.io';
const DEFAULT_LOGIN_REDIRECT_URL = process.env.DEFAULT_LOGIN_REDIRECT_URL || 'https://app.truecnh.io';
const PARTNER_LOGIN_REDIRECT_URL = process.env.PARTNER_LOGIN_REDIRECT_URL || 'https://partner.truecnh.io';

const WATR_ROOT_DOMAIN = 'watrid.com';

export {
  ROOT_DOMAIN,
  DEFAULT_LOGIN_REDIRECT_URL,
  PARTNER_LOGIN_REDIRECT_URL,
  WATR_ROOT_DOMAIN,
};
