import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PasswordResetRequest, PasswordResetRequestConfirmation } from './PasswordResetRequest';
import { PasswordResetSet, PasswordResetSuccess } from './PasswordResetSet';

const PasswordReset = () => (
  <Switch>
    <Route exact path="/password/reset" component={PasswordResetRequest}/>
    <Route exact path="/password/reset/sent" component={PasswordResetRequestConfirmation}/>
    <Route exact path="/password/reset/success" component={PasswordResetSuccess}/>
    <Route exact path="/password/reset/:token" component={PasswordResetSet}/>
  </Switch>
);

export { PasswordReset };
