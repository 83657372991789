import { getCookie } from 'lib/cookie';

const SESSION_EXPIRES_AT_COOKIE = 'session_expires_at';

function isAuthenticated() {
  // just gives an approximate idea about login status
  const sessionExpiresAtStr = getCookie(SESSION_EXPIRES_AT_COOKIE);

  if (!sessionExpiresAtStr) {
    return false;
  }

  const sessionExpiresAt = new Date(sessionExpiresAtStr);
  const authenticated = (new Date()) < sessionExpiresAt;
  return authenticated;
}

export { isAuthenticated };
