import React, { useState, useEffect } from 'react';

import { Content } from 'components/layout/Content';
import { H2 } from 'components/common/Header';
import { ReturnLink } from 'components/common/ReturnLink';

import { apiClient } from 'lib/api';

const LOGOUT_URL = '/web/logout';

export function InnerLogout({ loggedOut }) {
  return (
    <Content centered>
      {loggedOut &&
        <>
          <H2> You have successfully logged out. </H2>
          <ReturnLink to="/login"> Return to sign in </ReturnLink>
        </>
      }
    </Content>
  )
}

function Logout(props) {

  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    apiClient.post(LOGOUT_URL).then((res) => {
      console.log(res);
      setLoggedOut(true);
    });
  }, []);

  return (
    <InnerLogout loggedOut={loggedOut} />
  );
}

export { Logout };
