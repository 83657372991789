import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/common/Button';

const PrimaryButton = styled(Button)`
  display: block;
  margin: 35px auto 0;
  width: 100%;

  @media (min-width: 600px) {
    width: auto;
  }
`;

export { PrimaryButton };
