import { apiClient } from 'lib/api';

const MFA_RESET_URL = '/otp_device/reset';

function getUserMFAResetSetupInfo(token) {
  return apiClient.post(MFA_RESET_URL, { otp_reset_token: token});
}

function confirmMFAResetDevice(otpDeviceId, userId, code) {
  const url = `/otp_devices/${otpDeviceId}/reset_confirm`;
  return apiClient.post(url, {user_id: userId, token: code});
}

export { getUserMFAResetSetupInfo, confirmMFAResetDevice };
