import React from 'react';
import styled from 'styled-components';
import greenCheckmarkUrl from 'images/green-checkmark.svg';

const SuccessGraphicImage = styled.img`
  width: 100px;
  text-align: center;
  margin-bottom: 32px;
`;

const SuccessGraphic = () => {
  const imgUrl = greenCheckmarkUrl;

  return (
    <SuccessGraphicImage src={imgUrl}/>
  );
}

export { SuccessGraphic };
