import MaisonNeueExtraThinWoff2 from './fonts/MaisonNeue-ExtraThin.woff2';
import MaisonNeueExtraThinWoff from './fonts/MaisonNeue-ExtraThin.woff';
import MaisonNeueLightWoff2 from './fonts/MaisonNeue-Light.woff2';
import MaisonNeueLightWoff from './fonts/MaisonNeue-Light.woff';
import MaisonNeueBookWoff2 from './fonts/MaisonNeue-Book.woff2';
import MaisonNeueBookWoff from './fonts/MaisonNeue-Book.woff';
import MaisonNeueMediumWoff2 from './fonts/MaisonNeue-Medium.woff2';
import MaisonNeueMediumWoff from './fonts/MaisonNeue-Medium.woff';
import MaisonNeueDemiWoff2 from './fonts/MaisonNeue-Demi.woff2';
import MaisonNeueDemiWoff from './fonts/MaisonNeue-Demi.woff';
import MaisonNeueBoldWoff2 from './fonts/MaisonNeue-Bold.woff2';
import MaisonNeueBoldWoff from './fonts/MaisonNeue-Bold.woff';
import MaisonNeueExtraBoldWoff2 from './fonts/MaisonNeue-ExtraBold.woff2';
import MaisonNeueExtraBoldWoff from './fonts/MaisonNeue-ExtraBold.woff';
import MaisonNeueExtendedDemiWoff2 from './fonts/MaisonNeueExtended-Demi.woff2';
import MaisonNeueExtendedDemiWoff from './fonts/MaisonNeueExtended-Demi.woff';
import MaisonNeueExtendedBoldWoff2 from './fonts/MaisonNeueExtended-Bold.woff2';
import MaisonNeueExtendedBoldWoff from './fonts/MaisonNeueExtended-Bold.woff';
import PoppinsThinTtf from './fonts/Poppins-Thin.ttf';
import PoppinsExtraLightTtf from './fonts/Poppins-ExtraLight.ttf';
import PoppinsLightTtf from './fonts/Poppins-Light.ttf';
import PoppinsRegularTtf from './fonts/Poppins-Regular.ttf';
import PoppinsMediumTtf from './fonts/Poppins-Medium.ttf';
import PoppinsSemiBoldTtf from './fonts/Poppins-SemiBold.ttf';
import PoppinsBoldTtf from './fonts/Poppins-Bold.ttf';
import PoppinsExtraBoldTtf from './fonts/Poppins-ExtraBold.ttf';

export const fonts = `
  /* MaisonNeue Font Family */

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueExtraThinWoff2}') format('woff2'),
        url('${MaisonNeueExtraThinWoff}') format('woff');
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueLightWoff2}') format('woff2'),
        url('${MaisonNeueLightWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueBookWoff2}') format('woff2'),
        url('${MaisonNeueBookWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueMediumWoff2}') format('woff2'),
        url('${MaisonNeueMediumWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueDemiWoff2}') format('woff2'),
        url('${MaisonNeueDemiWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueBoldWoff2}') format('woff2'),
        url('${MaisonNeueBoldWoff}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'MaisonNeue';
    src: url('${MaisonNeueExtraBoldWoff2}') format('woff2'),
        url('${MaisonNeueExtraBoldWoff}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  /* MaisonNeueExtended Font Family */

  @font-face {
    font-family: 'MaisonNeueExtended';
    src: url('${MaisonNeueExtendedDemiWoff2}') format('woff2'),
        url('${MaisonNeueExtendedDemiWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MaisonNeueExtended';
    src: url('${MaisonNeueExtendedBoldWoff2}') format('woff2'),
        url('${MaisonNeueExtendedBoldWoff}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  /* Poppins Font Family */

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsThinTtf}') format('truetype');
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsExtraLightTtf}') format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsLightTtf}') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsRegularTtf}') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  font-face {
    font-family: 'Poppins';
    src: url('${PoppinsMediumTtf}') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsSemiBoldTtf}') format('truetype'));
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsBoldTtf}') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${PoppinsExtraBoldTtf}') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
`;
