import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MFAResetSet } from 'components/MFAReset/MFAResetSet'
import { MFAResetSuccess } from 'components/MFAReset/MFAResetSuccess'


const MFAReset = () => (
  <Switch>
    <Route exact path="/mfa/reset/success" component={MFAResetSuccess}/>
    <Route exact path="/mfa/reset/:token" component={MFAResetSet}/>
  </Switch>
);

export { MFAReset };
