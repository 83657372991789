import React from 'react';

import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';

function PageNotFound(props) {
  return (
    <Content centered>
      <H1> Page not found</H1>
      <Explanation>
        You have reached a page that does not exist.
      </Explanation>
    </Content>
  );
}

export { PageNotFound };
