import React from 'react';
import styled from 'styled-components';

import { DEFAULT_LOGIN_REDIRECT_URL } from 'lib/constants';
import logoImgUrl from 'images/trueusd-white.svg';

let TopBarContainer = styled.div`
  grid-area: header;
  padding-left: 22px;
  padding-right: 22px;
  font-family: 'MaisonNeueExtended', sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  background: rgb(8, 17, 30);
  color: white;
  font-size: 14px;
  letter-spacing: 1.4px;
  font-weight: 600;
  height: 56px;
`;

let TopBarLogo = styled.img`
  width: 100px;
  height: 100%;
`;

const ReturnLinkContainer = styled.div`
  margin-left: auto;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }

`;

const ReturnLink = styled.a`
  color: white;
  font-size: 14px;
`;

const LogoLink = styled.a`
  color: white;
  font-size: 20px;
`;

function TopBar() {
  let logoUrl = logoImgUrl;
  let loginRedirectUrl = DEFAULT_LOGIN_REDIRECT_URL;

  return (
    <TopBarContainer>
      <LogoLink href={loginRedirectUrl}>
        TCNH
      </LogoLink>

      <ReturnLinkContainer>
        <ReturnLink href={loginRedirectUrl}> Return to main site &rarr; </ReturnLink>
      </ReturnLinkContainer>
    </TopBarContainer>
  );
}

export { TopBar };
