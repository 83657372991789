import * as React from 'react';
import * as formik from 'formik';
import styled from 'styled-components';

import errorIconUrl from 'images/error-icon.svg';

const ErrorMessageWrapper = styled.div`
  color: ${(props) => props.theme.colors.error};
  margin-top: 13px;
  font-size: ${(props) => props.large ? '16px' : '13px'};
  display: flex;
  align-items: center;
`;

const ErrorIconImg = styled.img`
  width: ${(props) => props.large ? '18px' : '14px'};
  height: ${(props) => props.large ? '18px' : '14px'};
  margin-right: 5px;
`;

const ErrorMessageText = styled.span`
`;

function ErrorMessage({name, large = false}) {
  return (
    <formik.ErrorMessage name={name}>
      {(errorMsg) => (
        <ErrorMessageWrapper large={large}>
          <ErrorIconImg src={errorIconUrl} large={large}/>
          <ErrorMessageText>{errorMsg}</ErrorMessageText>
        </ErrorMessageWrapper>
      )}
    </formik.ErrorMessage>
  );
}

export { ErrorMessage };
