import React from 'react';

import styled from 'styled-components';

export const Footer = styled.div`
  grid-area: footer;
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`;
