import React from 'react';
import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';
import { SuccessGraphic } from 'components/common/SuccessGraphic';

function SimplePage({ title, description, successGraphic, extraContent }) {
  return (
    <Content centered>
      {successGraphic && <SuccessGraphic />}
      <H1> {title} </H1>
      {description &&
        <Explanation>
          {description}
        </Explanation>
      }
      {extraContent}
    </Content>
  );
}

export { SimplePage };
