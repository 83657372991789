import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Formik } from 'formik';
import styled from 'styled-components';

import { PrimaryButton } from 'components/common/PrimaryButton';
import { H1, Explanation } from 'components/common/Header';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { Content } from 'components/layout/Content';
import { MFACodeFieldWithError, mfaCodeValidationSchema } from 'components/MFA/common';
import { getUserMFASetupInfo } from 'components/MFA/api';
import { apiClient } from 'lib/api';
import { getLocationState } from 'lib/routing';
import { redirectToMainApp } from 'lib/routing';
import queryString from 'query-string';
import { applicationState } from 'data/state';
const QRInstructionsWrapper = styled.div`
  text-align: center;
  padding: 0 100px;
`;

const HelpLink = styled.a`
  font-size: 14px;
  text-decoration: none;
`;

const QRInstructions = styled.div`
  text-align: center;
  padding: 10px 0 10px;
  color: ${(props) => props.theme.colors.darkgrey};
  font-size: 16px;
  line-height: 24px;
`;

const QRCodeWrapper = styled.div`
  text-align: center;
  padding: 10px 0 10px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

const MFASetupForm = styled(PrimaryForm)`
  margin-top: 10px;
`;

function MFASetupFormComponent(props) {
  const {values, errors, isSubmitting} = props;

  const submitEnabled = values.mfaCode && !isSubmitting;

  return (
    <MFASetupForm>
      <MFACodeFieldWithError name="mfaCode"/>
      {!applicationState.watr &&
        <div style={{ marginTop: '10px' }}>
          <HelpLink href="https://support.trusttoken.com/hc/en-us/articles/360027744312-What-is-2-Factor-Authentication-2FA-" target="_blank">
            Need help setting the 2FA?
          </HelpLink>
        </div>
      }
      <SubmitButton type="submit" disabled={!submitEnabled}> Continue </SubmitButton>
    </MFASetupForm>
  );
}

const MFA_CONFIRM_BASE_URL = '/users/me/otp_devices';

function confirmMFADevice(otpDeviceId, code, loginStateToken=null) {
  const url = `${MFA_CONFIRM_BASE_URL}/${otpDeviceId}/confirm`;
  const data: any = {token: code};
  if (loginStateToken) {
    data.login_state_token = loginStateToken;
  }
  return apiClient.post(url, data);
}

function MFASetup(props) {
  const queryArgs = queryString.parse(props.location.search);

  // get MFA data from previous create account page's API call
  const initialMFASetupData = getLocationState(props.location, 'mfaSetupData');
  const loginStateToken = getLocationState(props.location, 'loginStateToken', queryArgs.login_state_token);

  const [mfaSetupData, setMfaSetupData] = useState(initialMFASetupData);

  useEffect(() => {
    // if not preloaded, refetch it
    if (!mfaSetupData) {
      const data: any = {};
      if (loginStateToken) {
        data.login_state_token = loginStateToken;
      }

      getUserMFASetupInfo(data).then((res) => {
        const mfaSetupData = res.data;
        setMfaSetupData(mfaSetupData);
      });
    }
  }, []);

  let otpDevice;
  if (mfaSetupData) {
    otpDevice = mfaSetupData.otp_device;
  }

  const mfaAlreadySetup = (mfaSetupData && otpDevice === null);

  const submitMFAConfirmation = (values, actions) => {
    confirmMFADevice(otpDevice.id, values.mfaCode, loginStateToken)
      .then((resp) => {
        redirectToMainApp();
      })
      .catch((error) => {
        let errorMsg = 'Problem creating account';
        if (error.response && error.response.data) {
          errorMsg = error.response.data.message;
        }
        actions.setErrors({mfaCode: errorMsg});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Content centered>
      {applicationState.watr ?
        <H1>Two-step verification</H1>
        :
        <H1>Two-factor authentication (2FA)</H1>
      }

      {mfaAlreadySetup && <QRInstructionsWrapper> Multi-factor authentication device is already setup </QRInstructionsWrapper>}

      {otpDevice &&
        <>
          <QRInstructionsWrapper>
            <QRInstructions>
              Scan the QR code with an authenticator app.
            </QRInstructions>
            {applicationState.watr &&
              <HelpLink href="https://app.watrid.com/mfa-info" target="_blank">
                Need help?
              </HelpLink>
            }
            <QRCodeWrapper>
              <QRCode value={otpDevice.uri} size={240}/>
            </QRCodeWrapper>
            <QRInstructions>
            {applicationState.watr ?
              'Then enter the verification code generated by your authenticator app.'
              :
              'Then enter the 6-digit code generated by your authenticator app.'
            }
            </QRInstructions>
          </QRInstructionsWrapper>

          <Formik
             initialValues={{ mfaCode: '' }}
             onSubmit={submitMFAConfirmation}
             component={MFASetupFormComponent}
             validationSchema={mfaCodeValidationSchema}
          />
        </>
      }

      {/*<BottomLink to="/">Need an authenticator app? </BottomLink>*/}
    </Content>
  );
}

export { MFASetup , MFASetupForm, QRInstructionsWrapper, QRInstructions, QRCodeWrapper, SubmitButton };
