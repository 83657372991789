import React, { useState, useEffect } from 'react';

import { apiClient } from 'lib/api';
import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';

export function InnerHome({ email }) {
  return (
    <Content centered>
      <H1>Home</H1>
      <Explanation>
        Welcome {email}
      </Explanation>
    </Content>
  )
}

function Home() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    apiClient.get('users/me').then((res) => {
      const user = res.data;
      setUser(user);
    });
  }, []);

  if (!user) {
    return null;
  }

  return <InnerHome email={user.email} />;
}

export { Home };
