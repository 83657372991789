import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Formik } from 'formik';
import { H1 } from 'components/common/Header';
import { Content } from 'components/layout/Content';
import { MFACodeFieldWithError, mfaCodeValidationSchema } from 'components/MFA/common';
import { getUserMFAResetSetupInfo, confirmMFAResetDevice } from 'components/MFAReset/api';
import { MFASetupForm, QRInstructionsWrapper, QRInstructions, QRCodeWrapper, SubmitButton } from 'components/MFA/MFASetup'

function MFASetupFormComponent(props) {
  const {values, errors, isSubmitting} = props;

  const submitEnabled = values.mfaCode && !isSubmitting;

  return (
    <MFASetupForm>
      <MFACodeFieldWithError name="mfaCode"/>
      <SubmitButton type="submit" disabled={!submitEnabled}> Continue </SubmitButton>
    </MFASetupForm>
  );
}

function MFAResetSet(props) {
  const resetToken = props.match.params.token;
  const [mfaSetupData, setMfaSetupData] = useState(null);

  useEffect(() => {
    getUserMFAResetSetupInfo(resetToken).then((res) => {
      const mfaSetupData = res.data;
      setMfaSetupData(mfaSetupData);
    });
  }, []);
  
  let otpDevice;
  let userId;
  if (mfaSetupData) {
    otpDevice = mfaSetupData.otp_device;
    userId = mfaSetupData.user_id;
  }

  const submitMFAConfirmation = (values, actions) => {
    confirmMFAResetDevice(otpDevice.id, userId, values.mfaCode)
      .then((resp) => {
        props.history.push('/mfa/reset/success');
      })
      .catch((error) => {
        let errorMsg = 'Problem resetting two-step verification';
        if (error.response && error.response.data) {
          errorMsg = error.response.data.message;
        }
        actions.setErrors({mfaCode: errorMsg});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  let header = 'Reset two-step verification';
  return (
    <Content centered>
      <H1> {header} </H1>

      {otpDevice &&
        <>
          <QRInstructionsWrapper>
            <QRInstructions>
              Scan code with an authenticator app.
             </QRInstructions>
            <QRCodeWrapper>
              <QRCode value={otpDevice.uri} size={240}/>
            </QRCodeWrapper>
            <QRInstructions>
              Then enter the verification code generated by your authenticator app.
             </QRInstructions>
          </QRInstructionsWrapper>

          <Formik
             initialValues={{ mfaCode: '' }}
             onSubmit={submitMFAConfirmation}
             component={MFASetupFormComponent}
             validationSchema={mfaCodeValidationSchema}
          />
        </>
      }

    </Content>
  );
}

export { MFAResetSet };
