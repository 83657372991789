import * as React from 'react';
import styled from 'styled-components';
import * as formik from 'formik';
import { ErrorMessage } from 'components/form/ErrorMessage';

const FieldWrapper = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
`;

const FieldLabel = styled.div`
  color: ${({ theme }) => theme.input.label.default};
  padding-bottom: 8px;
  padding-left: 1px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
`;

const Field = styled(formik.Field)`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  border-radius: 1px;
  outline: none;
  ${({ disabled, theme }) => disabled && `background: ${theme.colors.neutral.light};` }
`;

const StyledInput = styled.input`
  border-color: ${({ theme }) => theme.input.border.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};

  &:hover, &:focus {
    border-color: ${({ theme }) => theme.input.border.dark};
  }

  ${({ hasError, theme }) => hasError && `
    border-color: ${theme.colors.red.default};

    &:hover, &:focus {
      border-color: ${theme.colors.red.dark};
    }
  `}
`;

function DefaultInputComponent({ field, form, ...props }) {
  return (
    <StyledInput {...props} {...field} hasError={form.errors[field.name] && form.touched[field.name]} />
  );
}

function FieldWithExtras(props) {
  const { component, label, labelComponent, type, name, autofocus, ...otherProps } = props;
  const fieldRef = React.useRef(null);

  // React.useEffect(() => {
  //   if (autofocus) {
  //     fieldRef.current.focus();
  //   }
  // }, []);

  return  (
    <FieldWrapper>
      {label && <FieldLabel> {label} </FieldLabel>}
      {labelComponent && labelComponent}
      <Field
        type={type}
        name={name}
        {...otherProps}
        innerRef={fieldRef}
        component={component || DefaultInputComponent}
      />
      <ErrorMessage name={name}/>
    </FieldWrapper>
  );

}

export { Field, FieldWrapper, FieldLabel, FieldWithExtras };
