import React from 'react';
import { ReturnLink } from 'components/common/ReturnLink';
import { SimplePage } from 'components/common/SimplePage';

const MFAResetSuccess = () => (
    <SimplePage
      title="Two-step verification reset complete"
      description="Your 2FA was successfully reset!"
      successGraphic={true}
      extraContent={
        <ReturnLink to="/login"> Return to sign in </ReturnLink>
      }
    />
  );
  
  export { MFAResetSuccess };
