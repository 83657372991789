import * as yup from 'yup';
const zxcvbn = require('zxcvbn');

const enforcePasswordPolicy = process.env.SKIP_PASSWORD_POLICY === '1' ? false : true;

function strongPasswordChecker(password) {
  const result = zxcvbn(password);
  return result.score == 4;
}

const passwordStrengthMessage = `Password strength: Low`;

let newPasswordValidator = yup.string()
  .required('Please enter a password');

if (enforcePasswordPolicy) {
  newPasswordValidator = newPasswordValidator.test('strong-password', passwordStrengthMessage, strongPasswordChecker);
}

export { newPasswordValidator };
