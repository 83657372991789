import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { GlobalStyles } from 'styles/GlobalStyles';
import { Page, TopBar, ContentSection, Footer } from 'components/layout';
import { PageNotFound } from 'components/PageNotFound';
import routes from 'data/routes';
import { isDeployedEnv } from 'lib/env';
import { applicationState } from 'data/state';
import faviconUrl from 'images/favicon.png';

const Root = () => {
  let title = 'TCNH';
  let favicon = faviconUrl;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        {(isDeployedEnv() && !applicationState.watr) &&
          <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=3de44219-d862-43c0-850f-1c7d65ad5b50" />
        }
      </Helmet>
      <GlobalStyles />
      <Page>
        <TopBar />

        <ContentSection>
          <Switch>
            {routes.map((route) =>
              <Route key={route.path} {...route} />
            )}
            <Redirect exact from="/" to="/login" />
            <Route component={PageNotFound} />
          </Switch>
        </ContentSection>

        <Footer />
      </Page>
    </React.Fragment>
  );
}


export default Root;
