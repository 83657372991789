import React from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';

import { ErrorMessage } from 'components/form/ErrorMessage';

const mfaCodeValidationSchema = yup.object().shape({
  mfaCode: yup.string()
    .min(6, 'Must be 6 characters')
    .max(6, 'Must be 6 characters')
    .required('Code is required'),
});

const MFACodeFieldWrapper = styled.div`
  width: 250px;
  margin: 0 auto 0px;
`;

const MFACodeField = styled(formik.Field)`
  font-size: 20px;
  width: 100%;
  height: 60px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  outline: none;
  text-align: center;
`;

// oops doesn't work
// const MFACodeErrorMessage = styled(ErrorMessage)`
//   font-size: 16px;
// `;

const MFACodeFieldWithError = ({name, ...otherProps}) => (
  <MFACodeFieldWrapper>
    <MFACodeField type="text" name={name} maxLength="6" {...otherProps}/>
    <ErrorMessage name={name} large/>
  </MFACodeFieldWrapper>
);

export { MFACodeField, MFACodeFieldWithError, mfaCodeValidationSchema };
