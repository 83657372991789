import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { FieldWithExtras } from 'components/form/Field';
import { ReturnLink } from 'components/common/ReturnLink';
import { SimplePage } from 'components/common/SimplePage';
import { apiClient, parseAPIError } from 'lib/api';
import { getLocationState } from 'lib/routing';

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
});

function PasswordResetRequestFormComponent(props) {
  const {values, isSubmitting} = props;

  const submitEnabled = values.email && !isSubmitting;

  return(
    <PrimaryForm>
      <FieldWithExtras type="email" name="email" label="Your email"/>
      <PrimaryButton type="submit" disabled={!submitEnabled}> Reset Password </PrimaryButton>
    </PrimaryForm>
  );
}

const PASSWORD_RESET_REQUEST_URL = '/password/reset/request';

function requestPasswordReset(email) {
  return apiClient.post(PASSWORD_RESET_REQUEST_URL, {email: email});
}

function PasswordResetRequest(props) {
  const initialEmail = getLocationState(props.location, 'email', '');

  const submitResetRequest = (values, actions) => {
    requestPasswordReset(values.email)
      .then((resp) => {
        props.history.push('/password/reset/sent');
      })
      .catch((error) => {
        const errorInfo = parseAPIError(error);
        actions.setErrors({email: errorInfo.message});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Content>
      <H1> Reset your password </H1>
      <Explanation>
        Please submit your email address to reset your password.
       </Explanation>
      <Formik
         initialValues={{ email: initialEmail }}
         onSubmit={submitResetRequest}
         component={PasswordResetRequestFormComponent}
         validationSchema={validationSchema}
      />
    </Content>
  );
}

const PasswordResetRequestConfirmation = () => (
  <SimplePage
    title="Password reset sent"
    description="We've just emailed your instructions on how to reset your password."
    successGraphic={true}
    extraContent={
      <ReturnLink to="/login"> Return to sign in </ReturnLink>
    }
  />
);

export { PasswordResetRequest, PasswordResetRequestConfirmation };
