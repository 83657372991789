import * as React from 'react';
import styled from 'styled-components';

const ButtonComponent = (props) => {
  const { children, disabled, loading, hollow, secondary, simple, ...restProps } = props;
  return (
    <button disabled={disabled || loading} {...restProps}>
      {/* Processing generic enought to refer to loading or submitting */}
      {loading ? 'Processing...' : children}
    </button>
  );
};

export const Button = styled(ButtonComponent)`
  background: ${(props) => props.theme.button.colors.primary.default};
  color: white;
  min-width: 173px;
  padding: 15px 50px;
  border: none;
  border-radius: 999px;
  outline: none;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.button.colors.primary.light};
  }

  &:active {
    background: ${(props) => props.theme.button.colors.primary.dark};
  }

  ${(props) => {
    // console.log(props);
    return 'appearance: none';
  }}

  ${(props) => props.hollow && `
    background: white;
    color: ${props.theme.button.colors.primary.default};
    border: 1px solid ${props.theme.button.colors.primary.default};

    &:hover {
      background: white;
      color: ${props.theme.button.colors.primary.dark};
      border: 1px solid ${props.theme.button.colors.primary.dark};
    }

    &:active {
      background: ${props.theme.button.colors.primary.light};
    }
  `}

  ${(props) => props.secondary && `
    background: ${props.theme.button.colors.secondary.default};

    &:hover {
      background: ${props.theme.button.colors.secondary.light};
    }

    &:active {
      background: ${props.theme.button.colors.secondary.dark};
    }
  `}

  ${(props) => props.secondary && props.hollow && `
    background: white;
    color: ${props.theme.button.colors.secondary.default};
    border: 1px solid ${props.theme.button.colors.secondary.default};

    &:hover {
      background: white;
      color: ${props.theme.button.colors.secondary.dark};
      border: 1px solid ${props.theme.button.colors.secondary.dark};
    }

    &:active {
      background: ${props.theme.button.colors.secondary.light};
    }
  `};

  ${(props) => props.disabled && `
    background: ${props.theme.button.colors.disabled};
    pointer-events: none;
    cursor: not-allowed;
  `}

  ${(props) => props.disabled && `
    background: ${props.theme.button.colors.disabled};
    pointer-events: none;
    cursor: not-allowed;
  `}
`;
