import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { FieldWithExtras } from 'components/form/Field';
import { ReturnLink } from 'components/common/ReturnLink';
import { hasErrors } from 'lib/form';
import { SimplePage } from 'components/common/SimplePage';
import { apiClient, parseAPIError } from 'lib/api';
import { newPasswordValidator } from 'lib/password';

const validationSchema = yup.object().shape({
  password: newPasswordValidator,
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please re-enter your password'),
});

function PasswordResetFormComponent(props) {
  const {values, errors, isSubmitting} = props;

  const submitEnabled = values.password && values.passwordConfirmation && !hasErrors(errors) && !isSubmitting;

  return(
    <PrimaryForm>
      <FieldWithExtras type="password" name="password" label="New password"/>
      <FieldWithExtras type="password" name="passwordConfirmation" label="Confirm password"/>
      <PrimaryButton type="submit" disabled={!submitEnabled}> Reset Password & Sign In </PrimaryButton>
    </PrimaryForm>
  );
}

const PASSWORD_RESET_REQUEST_URL = '/password/reset';

function resetPassword(token, newPassword) {
  return apiClient.post(
    PASSWORD_RESET_REQUEST_URL,
    { password_reset_token: token, new_password: newPassword }
  );
}

function PasswordResetSet(props) {

  const resetToken = props.match.params.token;

  const submitResetPassword = (values, actions) => {
    resetPassword(resetToken, values.password)
      .then((resp) => {
        props.history.push('/password/reset/success');
      })
      .catch((error) => {
        // figure out top-level message later..
        const errorInfo = parseAPIError(error);
        actions.setErrors({passwordConfirmation: errorInfo.message});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Content>
      <H1> Reset your password </H1>
      <Explanation>
        Almost done, just enter your new password below.
       </Explanation>
      <Formik
         initialValues={{ password: '', passwordConfirmation: '' }}
         onSubmit={submitResetPassword}
         component={PasswordResetFormComponent}
         validationSchema={validationSchema}
      />
    </Content>
  );
}

const PasswordResetSuccess = () => (
  <SimplePage
    title="Password reset complete"
    description="Your password was successfully reset!"
    successGraphic={true}
    extraContent={
      <ReturnLink to="/login"> Return to sign in </ReturnLink>
    }
  />
);

export { PasswordResetSet, PasswordResetSuccess };
