import React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Root from 'components/Root';
import  { theme } from 'styles/theme';
import { watrTheme } from 'styles/watr-theme';
import createBrowserHistory from 'history/createBrowserHistory';
import { registerUnauthorizedInterceptor } from 'lib/api';
import { applicationState } from 'data/state';

const history = createBrowserHistory();

console.log('test');


// determine theme

let themeToUse = theme;

if (process.env.ENV === 'production') {
  const hostname = window.location.hostname;
  const watr = hostname.includes('watrid');
  if (watr) {
    applicationState.watr = true;
    themeToUse = watrTheme;
  }
} else {
  const hostname = window.location.hostname;
  const watr = hostname.includes('watrid');
  if (watr) {
    applicationState.watr = true;
    themeToUse = watrTheme;
  }
}

// applicationState.watr = true;
// const themeToUse = theme;

registerUnauthorizedInterceptor(history);

ReactDOM.render(
  <ThemeProvider theme={themeToUse}>
    <Router history={history}>
      <Root />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
