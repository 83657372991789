import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  margin-top: ${(props) => props.topmargin ? '32px' : '0px'};
  margin-left: 14px;
  margin-right: 14px;
  text-align: center;
  font-size: ${(props) => props.fontSize || props.theme.header.h1.fontSize || '45px'};
  font-weight: ${(props) => props.theme.header.h1.fontWeight || 200};
  line-height: ${(props) => props.theme ? props.theme.header.h1.lineHeight : '48px'};
`;

const H2 = styled.h2`
  text-align: center;
`;

const Explanation = styled.div`
  color: ${(props) => props.theme.colors.darkgrey};
  margin-bottom: 14px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 0 10px;
`;

export { H1, H2, Explanation };
