import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EmailVerificationSuccess } from './EmailVerificationConfirmations';
import { EmailVerificationToken } from './EmailVerificationToken';

const EmailVerification = () => (
  <Switch>
    {/* <Route exact path="/email/verify/sent" component={EmailVerificationSent}/> */}
    <Route exact path="/email/verify/success" component={EmailVerificationSuccess}/>
    <Route exact path="/email/verify/:token" component={EmailVerificationToken}/>
  </Switch>
);

export { EmailVerification };
