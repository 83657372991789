import React from 'react';

import styled from 'styled-components';

export const Page = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-rows: 150px 1fr 50px;
  grid-template-areas: "header"
                       "content"
                       "footer";

  @media (min-width: 600px) {
    grid-template-rows: 150px 1fr 100px;
    grid-template-columns: 1fr 600px 1fr;
    grid-template-areas: "header header header"
                         ". content ."
                         "footer footer footer";
  }

`;
