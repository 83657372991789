import * as yup from 'yup';

function validateLogin(values) {
  const errors = {};

  return errors;
}

const loginValidationSchema = yup.object().shape({
  email: yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: yup.string().
    required('Password is required'),
  acceptTerms: yup.boolean()
    .required('Accept terms of service to continue'),
});

export { validateLogin, loginValidationSchema };
