import * as React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../form/ErrorMessage';
import { Field, FieldWrapper, FieldLabel, FieldWithExtras } from '../form/Field';

const StyledCheckbox = styled.input`
  width: auto;
`;

const CheckboxText = styled.span`
  padding-left: 12px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

function CheckboxWrapper({ field, form, options, text, ...otherProps }) {
  const handleClick = () => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        id={field.name}
        type="checkbox"
        defaultChecked={field.value}
        onClick={handleClick}
        {...otherProps}
      />
      <CheckboxText><label htmlFor={field.name}>{text}</label></CheckboxText>
    </CheckboxContainer>
  );
}

function CheckboxInput(props) {
  return (
    <FieldWithExtras
      component={CheckboxWrapper}
      {...props}
    />
  );
}

function FieldWithExtras(props) {
  const {
    component,
    tooltip,
    label,
    labelComponent,
    warningMessage,
    showWarningMessage,
    errorMessageComponent,
    withError = false,
    isSensitive,
    className,
    ...otherProps
  } = props;
  // quick hack with "noErrorMessage" to suppress making an ErrorMessage div

  // must make these capitalized to render
  const CustomLabelComponent = labelComponent;
  const CustomErrorMessageComponent = errorMessageComponent;

  const showDefaultErrorMessage = withError && !errorMessageComponent;
  const showCustomErrorComponent = !!errorMessageComponent;

  return (
    <FieldWrapper>
      {/*{tooltip && [<TooltipIcon key="fwetti" src={moreInfoIconUrl} data-tip={tooltip} />, <StyledReactTooltip key="fwett" type="light" effect="solid" />]} */}
      {!!labelComponent && <CustomLabelComponent />}
      {label && <FieldLabel>{label}</FieldLabel>}
      <Field
        component={component || DefaultInputComponent}
        className={`${isSensitive ? SENSITIVE : ''} ${className || ''}`}
        {...otherProps}
      />

      {showWarningMessage && <WarningMessage message={warningMessage} />}
      {showDefaultErrorMessage && <ErrorMessage name={otherProps.name} />}
      {showCustomErrorComponent && <CustomErrorMessageComponent />}

    </FieldWrapper>
  );
}

export { CheckboxInput };