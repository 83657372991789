import * as formik from 'formik';
import styled from 'styled-components';

const PrimaryForm = styled(formik.Form)`
  margin-top: 55px;
  padding: 0 25px;

  @media (min-width: 600px) {
    padding: 0 100px;
  }
`;

export { PrimaryForm };
