import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as formik from 'formik';
import styled from 'styled-components';
import queryString from 'query-string';

import { PrimaryButton } from 'components/common/PrimaryButton';
import { H1, Explanation } from 'components/common/Header';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { Content } from 'components/layout/Content';
import { MFACodeFieldWithError, mfaCodeValidationSchema } from 'components/MFA/common';
import { hasErrors } from 'lib/form';
import { apiClient, parseAPIError } from 'lib/api';
import { getLocationState, redirectToMainApp, redirectToNextUrl } from 'lib/routing';

const ResetMFALink = styled.a`
  font-size: 15px;
`;

function MFAVerifyFormComponent(props) {
  const {values, errors, isSubmitting} = props;

  const submitEnabled = values.mfaCode && !hasErrors(errors) && !isSubmitting;

  return (
    <PrimaryForm>
      <MFACodeFieldWithError name="mfaCode" autoFocus/>
      <PrimaryButton type="submit" disabled={!submitEnabled}> Continue </PrimaryButton>
    </PrimaryForm>
  );
}

const MFA_VERIFY_URL = '/login/verify_otp';
const MFA_VERIFY_TT_URL = '/login/verify_tt_otp'

function verifyMFA(stateToken, otpToken) {
  return apiClient.post(MFA_VERIFY_URL, {state_token: stateToken, otp_token: otpToken});
}

function verifyMFATT(stateToken, otpToken) {
  return apiClient.post(MFA_VERIFY_TT_URL, {state_token: stateToken, otp_token: otpToken});
}

function MFAVerify(props) {

  const queryArgs = queryString.parse(props.location.search);
  const stateToken = getLocationState(props.location, 'stateToken', queryArgs.state_token);
  const isTrustTokenUser = queryArgs.user ? queryArgs.user : 1;
  const nextUrl = queryArgs.next && queryArgs.next.replace('-', '=');  // to allow nextUrls with query parameters

  const submitMFAVerification = (values, actions) => {
    if (isTrustTokenUser === 1) {
      verifyMFA(stateToken, values.mfaCode)
      .then((resp) => {
        // success, go to main app or redirect url
        if (nextUrl) {
          redirectToNextUrl(nextUrl);
        } else {
          redirectToMainApp();
        }
      })
      .catch((error) => {
        const errorInfo = parseAPIError(error);
        const errorMsg = errorInfo.message || 'Wrong MFA code';
        actions.setErrors({mfaCode: errorMsg});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
    } else {
      verifyMFATT(stateToken, values.mfaCode)
      .then((resp) => {
        // success, go to main app or redirect url
        if (nextUrl) {
          redirectToNextUrl(nextUrl);
        } else {
          redirectToNextUrl(`/mfa-setup?login_state_token=${resp.data.state_token}&mfaSetupData=${resp.data.otp_device}`);
        }
      })
      .catch((error) => {
        const errorInfo = parseAPIError(error);
        const errorMsg = errorInfo.message || 'Wrong MFA code';
        actions.setErrors({mfaCode: errorMsg});
      })
      .then(() => {
        actions.setSubmitting(false);
      });
    }
  };

  return (
    <Content centered>
      {(stateToken) ?
        <>
          <H1> Two-step verification</H1>
          <Explanation> Enter the <b>TCNH</b> verification code generated by your mobile application </Explanation>
          <ResetMFALink href="https://goo.gl/forms/OIlRnsMTJbN0tPby2" target="_blank">
            Have an issue with 2FA? Click here to reset
          </ResetMFALink>
          <Formik
             initialValues={{ mfaCode: '' }}
             onSubmit={submitMFAVerification}
             component={MFAVerifyFormComponent}
             validationSchema={mfaCodeValidationSchema}
          />
        </> :
        <Explanation> Error, please go back and retry </Explanation>
      }
    </Content>
  );
}

export { MFAVerify };
