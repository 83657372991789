import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Content } from 'components/layout/Content';
import { H1, Explanation } from 'components/common/Header';
import { Button, PrimaryButton } from 'components/common';
import { apiClient, parseAPIError } from 'lib/api';
import queryString from 'query-string';
import { applicationState } from 'data/state';
import { isAuthenticated } from 'lib/auth';

const EMAIL_VERIFICATION_URL = '/email/verify';
const SEND_EMAIL_VERIFICATION_URL = '/email/verify/send';


function verifyEmail(token, email) {
  return apiClient.post(EMAIL_VERIFICATION_URL, { email_verification_token: token, email: email });
}

function sendVerificationEmail(email) {
  return apiClient.post(SEND_EMAIL_VERIFICATION_URL, { email: email });
}

function EmailVerificationToken(props) {
  const token = props.match.params.token;
  const queryArgs = queryString.parse(props.location.search);
  const email = queryArgs.email;

  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [emailStatus, setEmailStatus] = useState('unsent');

  const isLoggedIn = isAuthenticated();

  const submitEmailVerification = () => {
    verifyEmail(token, email)
    .then((resp) => {
      setStatus('success');  // not really necessary but whatever

      const newLocation = {
        pathname: '/email/verify/success',
        state: { email: email },
      }
      props.history.push(newLocation);
    })
    .catch((error) => {
      setStatus('error');
      const errorInfo = parseAPIError(error);
      const errorMsg = errorInfo.message || 'Problem verifying email';
      setError(errorMsg);
    });
  };

  const resendEmailVerification = () => {
    setEmailStatus('sending');
    sendVerificationEmail(email)
    .then((resp) => {
      setEmailStatus('sent');
    })
    .catch((error) => {
      setEmailStatus('sendError');
    });
  };

  useEffect(() => {
    submitEmailVerification();
  }, []
  );

  if (applicationState.watr && error == 'Email already verified') {
    return (
      <Content>
        <H1> Email verified </H1>
        <Explanation> <a href={"https://login.watrid.com/login"}>Click here to login and continue</a> </Explanation>
      </Content>
    );
  } else {
    return (
      <Content>
        <H1> Email verification </H1>
          {(status === 'loading') && <Explanation> Verifying email... </Explanation>}
          {(status === 'success') && <Explanation> Success </Explanation>}
          {(status === 'error') &&
            <>
              <Explanation> {error} </Explanation>
              <div>
                <PrimaryButton onClick={resendEmailVerification} disabled={emailStatus !== 'unsent'}>
                  {emailStatus === 'unsent' && 'Resend verification email'}
                  {emailStatus === 'sending' && 'Sending...'}
                  {emailStatus === 'sent' && 'Email sent'}
                </PrimaryButton>
              </div>
            </>
          }
      </Content>
    );
  }
}

export { EmailVerificationToken };
